import React from 'react';
import { createRoutesFromElements, Navigate, Route } from 'react-router-dom';
import SuspenseWrapper from './SuspenseWrapper';
import WebsiteLayout from 'components/Layout/PublicLayout';
import DocumentTitle from 'components/DocumentTitle';
import RequireAuth from './RequireAuth';
import AuthRoute from './AuthRoute';
import App from 'App';

// Lazy loaded components
const Page404 = React.lazy(() => import('components/Page404'));
const Register = React.lazy(() => import('pages/authentication/register/index'));
const TwoFactor = React.lazy(() => import('pages/authentication/TwoFactor'));
const Login = React.lazy(() => import('pages/authentication/Login'));
const PostJob = React.lazy(() => import('pages/post-job'));
const Jobs = React.lazy(() => import('pages/jobs'));
const ClientJobs = React.lazy(() => import('pages/client-jobs'));
const Search = React.lazy(() => import('pages/search'));
const ForgotPassword = React.lazy(() => import('pages/authentication/ForgotPassword'));
const AuthTerms = React.lazy(() => import('pages/authentication/Terms'));
const ResetPassword = React.lazy(() => import('pages/authentication/ResetPassword'));
const ViewFreelancerProfile = React.lazy(() => import('pages/view-freelancer-profile'));
const ClientProfile = React.lazy(() => import('pages/client-profile'));
const FreelacerDashboard = React.lazy(() => import('pages/freelancer-dashboard'));
const OfferDetails = React.lazy(() => import('pages/offer-details'));
const FreelancerProfileSettings = React.lazy(() => import('pages/freelancer-profile-settings'));
const ChangePassword = React.lazy(() => import('pages/change-password'));
const Support = React.lazy(() => import('pages/support'));
const Disputes = React.lazy(() => import('pages/disputes'));
const Messaging = React.lazy(() => import('pages/messaging'));
const Terms = React.lazy(() => import('pages/terms'));
const Privacy = React.lazy(() => import('pages/privacy'));
const Cookies = React.lazy(() => import('pages/cookies'));
const AboutUs = React.lazy(() => import('pages/about-us'));
const CompleteProfile = React.lazy(() => import('pages/complete-profile'));
const CustomerSupport = React.lazy(() => import('pages/customer-support'));
const TemplateDetails = React.lazy(() => import('pages/template-details'));
const ClientDashboard = React.lazy(() => import('pages/client-dashboard'));
const Payments = React.lazy(() => import('pages/payments'));
const Proposals = React.lazy(() => import('pages/proposals'));
const InvoiceGenerater = React.lazy(() => import('pages/payments/InvoiceGenerater'));
const PortfolioDetails = React.lazy(() => import('pages/portfolio-details'));
const JobDetails = React.lazy(() => import('pages/job-details'));
const ClientJobDetails = React.lazy(() => import('pages/client-job-details'));
const ProposalDiscussion = React.lazy(() => import('pages/proposal-discussion'));
const FindingUs = React.lazy(() => import('pages/finding-us'));
const TalkJS = React.lazy(() => import('pages/talk-js'));

const HomePage = React.lazy(
  /* webpackPrefetch: true */
  () => import('pages/home')
);

const publicRoutes = [
  {
    path: '/home',
    component: <HomePage />,
    title: 'ZehMizeh | The Jewish Freelancing Platform - Post projects, find talent, pay simply.',
    metaDescription:
      'Now hire the best talent in the world to get your project done and also find the best freelancing projects around the wold',
  },
  {
    path: '/404',
    component: <Page404 />,
  },
  {
    path: '/terms',
    component: <AuthTerms />,
    title: 'ZehMizeh | Terms and Conditions',
  },
  {
    path: '/terms-of-service',
    component: <Terms />,
    title: 'ZehMizeh | Register terms and conditions',
  },
  {
    path: '/privacy',
    component: <Privacy />,
    title: 'ZehMizeh | Privacy Policy',
  },
  {
    path: '/cookies',
    component: <Cookies />,
    title: 'ZehMizeh | Cookies',
  },
  {
    path: '/about-us',
    component: <AboutUs />,
    title: 'ZehMizeh | About Zehmizeh and how it works',
    metaDescription:
      'Know about ZehMizeh, how the platform works, how the hiring process is, learn about the infrastructure that we have created for Jewish businesses',
  },
  {
    path: '/customer-support',
    component: <CustomerSupport />,
    title: 'ZehMizeh | Customer Support to contact us for any query',
    metaDescription:
      'ZehMizeh | Customer Support, that helps you for all your queries, feel free to contact us for all your queries.',
  },
  {
    path: '/finding-us',
    component: <FindingUs />,
    title: 'ZehMizeh | Find ZehMiZeh Online: A Guide to Common Spelling Variations',
    metaDescription:
      'ZehMizeh | Discover the various spelling variations of "ZehMiZeh" to find us online without any hassle. Explore our guide for different spellings of our name to reach us easily for information, services, or to connect with us.',
  },
];
const authRoutes = [
  {
    path: '/login',
    component: <Login />,
    title: 'ZehMizeh | Login to the ZehMizeh platform',
    metaDescription:
      'ZehMizeh Login | Login to the ZehMizeh platform and get started today to find best talents or the best projects for you around the world.',
  },
  {
    path: '/reset-password',
    component: <ResetPassword />,
    title: 'ZehMizeh | Reset Password',
    metaDescription:
      'ZehMizeh | Reset your password from here by just verifying your email and entering a new password.',
  },
  {
    path: '/forgot-password',
    component: <ForgotPassword />,
    title: 'ZehMizeh | Forgot your password?',
    metaDescription:
      'ZehMizeh | forgot your passowrd? No worries, just verify your email with the otp and you will be able to set a new password.',
  },
  {
    path: '/register/:userType',
    component: <Register />,
    title: 'ZehMizeh | Register to ZehMizeh platform',
    metaDescription:
      'ZehMizeh Registration | Get registered today to find best talents or the best projects for you around the world.',
  },
  {
    path: '/2fa',
    component: <TwoFactor />,
    title: 'ZehMizeh | Two factor verification',
  },
];
const privateRoutes = [
  {
    path: '/dashboard',
    component: <FreelacerDashboard />,
    title: 'ZehMizeh | Freelancer Dashboard - Get all your summary here',
    metaDescription:
      'ZehMizeh Dashboard - A summary of your completed projects, your earnings, ratings & reviews, the invites you have received, your ongoing projects and many more',
  },
  {
    path: '/payments',
    component: <Payments />,
    title: 'ZehMizeh | Payments',
    metaDescription: 'ZehMizeh | Get a list of all the payment history of all your projects',
  },
  {
    path: 'client/dashboard',
    component: <ClientDashboard />,
    title: 'ZehMizeh | Client Dashboard - Get all your summary here',
    metaDescription:
      'ZehMizeh Dashboard - A summary of your completed projects, your hires, ratings & reviews, the proposals you have received, your ongoing projects and many more',
  },
  {
    path: '/post-new-job',
    component: <PostJob />,
    title: 'ZehMizeh | Post a Project with all your requirements',
    metaDescription:
      'ZehMizeh | Post a Project with all your requirements that will help to find the best freelancer for your project.',
  },
  {
    path: '/jobs',
    component: <Jobs />,
    title: 'ZehMizeh | My Projects - Freelancer',
    metaDescription:
      'ZehMizeh | Get a list of all the projects that you have applied on, the projects that you are working on, your closed projects and your saved projects too.',
  },
  {
    path: '/client-jobs',
    component: <ClientJobs />,
    title: 'ZehMizeh | My Projects - Client',
    metaDescription:
      'ZehMizeh | Get a list of all the projects those are in your draft, the projects that you have posted, your ongoing projects, your closed projects and your saved projects too.',
  },
  {
    path: '/edit/:type/:id',
    component: <PostJob />,
    title: 'ZehMizeh | Edit Project',
    metaDescription: 'ZehMizeh | Edit project with all the requirements changes that you want to do.',
  },
  {
    path: '/template/:type/',
    component: <PostJob />,
    title: 'ZehMizeh | Edit Template',
    metaDescription: 'ZehMizeh | Edit the template with all the changes that you want to do.',
  },
  {
    path: '/client-job-details/:id',
    component: <ClientJobDetails />,
    title: 'ZehMizeh | Project Details - get more details about the project',
    metaDescription:
      'ZehMizeh | Get all the details of the project that you have in your draft, your posted projects, your on going projects and the closed projects as well.',
    nestedRoutes: [
      {
        path: '/gen_details',
        component: <ClientJobDetails />,
      },
      {
        path: '/messages',
        component: <ClientJobDetails />,
      },
      {
        path: '/m_stone',
        component: <ClientJobDetails />,
      },
      {
        path: '/applicants',
        component: <ClientJobDetails />,
        nestedRoutes: [
          {
            path: '/:proposalId',
            component: <ClientJobDetails />,
          },
        ],
      },
      {
        path: '/invitees',
        component: <ClientJobDetails />,
        nestedRoutes: [
          {
            path: '/:inviteeId',
            component: <ClientJobDetails />,
          },
        ],
      },
      {
        path: '/feedback',
        component: <ClientJobDetails />,
      },
    ],
  },
  {
    path: '/offer-details/:id',
    component: <OfferDetails />,
    title: 'ZehMizeh | Offer Details - get more details about the offer',
    metaDescription:
      'ZehMizeh | Get all the details of the offer that you have received and then apply if it comes under your expertise.',
  },
  {
    path: '/freelancer/account/:tabkey',
    component: <FreelancerProfileSettings />,
    title: 'ZehMizeh | Freelancer Profile',
    metaDescription:
      'ZehMizeh | View or edit your profile details, education details, skills, languages, description, certificates, bank accounts',
  },
  {
    path: '/client/account/:clientId',
    component: <ClientProfile />,
    title: 'ZehMizeh | Client Profile',
    metaDescription:
      'ZehMizeh | View or edit your profile details, add or delete new bank accounts and credit cards for further use.',
  },
  {
    path: '/change-password',
    component: <ChangePassword />,
    title: 'ZehMizeh | Change Password',
    metaDescription:
      'ZehMizeh | Do you want to change your passowrd? Just enter your current password once for us to verify and you can change your password then.',
  },
  {
    path: '/support/:type?/:sectionId?',
    component: <Support />,
    title: 'ZehMizeh | Support - Submit inquiry or create dispute',
    metaDescription:
      'ZehMizeh | Support that allows you to contact the admin for any inquiries you have and also allows you to create a dispute on the projects you are working on',
  },
  {
    path: '/disputes',
    component: <Disputes />,
    title: 'ZehMizeh | My Disputes List',
    metaDescription: 'ZehMizeh | Your all disputes list with current status whether it is resolved or not.',
  },
  {
    path: '/messages',
    component: <Messaging />,
    title: 'ZehMizeh | Messages - chat with your clients or freelancers',
    metaDescription:
      'ZehMizeh | Messages - You can now messsage to your clients or freelancers for the projects that you are conneted for',
  },
  {
    path: '/messages/:conversationId',
    component: <Messaging />,
    title: 'ZehMizeh | Messages - chat with your clients or freelancers',
    metaDescription:
      'ZehMizeh | Messages - You can now messsage to your clients or freelancers for the projects that you are conneted for',
  },
  {
    path: '/messages-new',
    component: <TalkJS />,
    title: 'ZehMizeh | Messages - chat with your clients or freelancers',
    metaDescription:
      'ZehMizeh | Messages - You can now messsage to your clients or freelancers for the projects that you are conneted for',
  },
  {
    path: '/messages-new/:conversationId',
    component: <TalkJS />,
    title: 'ZehMizeh | Messages - chat with your clients or freelancers',
    metaDescription:
      'ZehMizeh | Messages - You can now messsage to your clients or freelancers for the projects that you are conneted for',
  },
  {
    path: '/template-details/:id',
    component: <TemplateDetails />,
    title: 'ZehMizeh | Template Details',
  },
  {
    path: '/client/proposals',
    component: <Proposals />,
    title: 'ZehMizeh | All Proposals',
    metaDescription:
      'ZehMizeh | Get your all proposals that you have recieved for all the projects that you have posted so far.',
  },
  {
    path: '/freelancer/portfolio/:id',
    component: <PortfolioDetails />,
    title: 'ZehMizeh | Portfolio details',
    metaDescription: 'ZehMizeh | Get all the details of the portfolio that a freelancer has created',
  },
  {
    path: '/proposal/discussion/:proposal_id',
    component: <ProposalDiscussion />,
    title: 'ZehMizeh | Portfolio details',
    metaDescription: 'ZehMizeh | Get all the details of the portfolio that a freelancer has created',
  },
  {
    path: '/search',
    component: <Search />,
    title: 'ZehMizeh | Search the best talents or best projects for you',
    metaDescription:
      'ZehMizeh | Find the best talents to get your project done or the best freelancing projects for you around the world with our filters that make it very easy',
  },
  {
    path: '/invoice/:id',
    component: <InvoiceGenerater />,
    title: 'ZehMizeh | Generate Invoice',
    metaDescription: 'ZehMizeh | Now you can genarate the invoices for your payments at anytime.',
  },
  {
    path: '/freelancer/:freelancerId',
    component: <ViewFreelancerProfile />,
    title: 'ZehMizeh | Freelancer profile that may fit to your project',
    metaDescription:
      'ZehMizeh | Know more about the freelancer before giving him a project to find the best one for you who can deliver the best service to you.',
    nestedRoutes: [
      {
        path: '/:tabkey',
        component: <ViewFreelancerProfile />,
      },
    ],
  },
  {
    path: '/job-details/:id',
    component: <JobDetails />,
    title: 'ZehMizeh | Project Details - get more details about the project',
    metaDescription:
      'ZehMizeh | Get all the details of the project that you want to apply or already applied, the project you are working on, the projects that you have already completed',
    nestedRoutes: [
      {
        path: '/gen_details',
        component: <JobDetails />,
      },
      {
        path: '/messages',
        component: <JobDetails />,
      },
      {
        path: '/m_stone',
        component: <JobDetails />,
      },
      {
        path: '/proposal_sent',
        component: <JobDetails />,
      },
      {
        path: '/feedback',
        component: <JobDetails />,
      },
    ],
  },
  {
    path: '/complete-profile',
    component: <CompleteProfile />,
    title: 'ZehMizeh | Complete my profile',
  },
];

export const appRoutes = () => {
  const privateRoutesWithNestedRoutes: any[] = [];

  // Recursive function to handle nested routes
  const recursiveRoutes = (routes: any[], path = '') => {
    routes.forEach((route) => {
      privateRoutesWithNestedRoutes.push({
        ...route,
        path: `${path}${route.path}`,
      });
      if (route?.nestedRoutes) {
        recursiveRoutes(route.nestedRoutes, path.concat(route.path));
      }
    });
  };

  recursiveRoutes(privateRoutes);

  return createRoutesFromElements(
    <Route path="/" element={<App />}>
      <Route element={<WebsiteLayout />} path="/">
        {/* Auth Routes */}
        {authRoutes.map(({ path, component, title, metaDescription }) => (
          <Route
            key={path}
            path={path}
            element={
              <AuthRoute>
                <>
                  {title && <DocumentTitle title={title} metaDescription={metaDescription} />}
                  <SuspenseWrapper>{component}</SuspenseWrapper>
                </>
              </AuthRoute>
            }
          />
        ))}

        {/* Public Routes */}
        {publicRoutes.map(({ path, component, title, metaDescription }) => (
          <Route
            key={path}
            path={path}
            element={
              <>
                {title && <DocumentTitle title={title} metaDescription={metaDescription} />}
                <SuspenseWrapper>{component}</SuspenseWrapper>
              </>
            }
          />
        ))}

        {/* Private Routes */}
        {privateRoutesWithNestedRoutes.map(({ path, component, title, metaDescription }) => (
          <Route
            key={path}
            path={path}
            element={
              <RequireAuth>
                <>
                  {title && <DocumentTitle title={title} metaDescription={metaDescription} />}
                  <SuspenseWrapper>{component}</SuspenseWrapper>
                </>
              </RequireAuth>
            }
          />
        ))}

        {/* Default and 404 Routes */}
        <Route path="" element={<Navigate to="/home" />} />
        <Route path="*" element={<Navigate replace to="/404" />} />
      </Route>
    </Route>
  );
};
