import React, { useEffect, useState } from 'react';
import { ThemeProvider } from 'styled-components';
import { IntercomProvider } from 'react-use-intercom';
import { QueryClient, QueryClientProvider } from 'react-query';
import { Outlet, useLocation } from 'react-router-dom';
import { Toaster } from 'react-hot-toast';
import AOS from 'aos';
import { AosOptions } from 'aos';
import ReactGA from 'react-ga';

import { AuthProvider } from 'helpers/contexts/auth-context';
import { MyGlobalStyle } from './styles/GlobalStyle';
import { myTheme } from './styles/theme';
import { CssUtils } from 'styles/CssUtils';
import AppLayout from 'components/Layout/AppLayout';
import { MetaTags } from 'components/MetaTags';
import { isStagingEnv } from 'helpers/utils/helper';
import { setCookie } from 'helpers/utils/cookieHelper';

// Initialize QueryClient with optimal settings
const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: 1,
      staleTime: 5 * 60 * 1000, // 5 minutes
    },
  },
});

// Constants
const INTERCOM_APP_ID = process.env.REACT_APP_INTERCOM_APP_ID;
const GA_TRACKING_CODE = process.env.REACT_APP_GA_TRACKING_CODE;

// Google Analytics setup
const initGA = () => {
  ReactGA.initialize(GA_TRACKING_CODE);
};

const logPageView = () => {
  ReactGA.set({ page: window.location.pathname });
  ReactGA.pageview(window.location.pathname);
};

// AOS Animation configuration
const AOS_CONFIG: AosOptions = {
  disable: false,
  startEvent: 'DOMContentLoaded',
  initClassName: 'aos-init',
  animatedClassName: 'aos-animate',
  useClassNames: false,
  disableMutationObserver: false,
  debounceDelay: 50,
  throttleDelay: 99,
  offset: 120,
  delay: 0,
  duration: 400,
  easing: 'ease',
  once: true,
  mirror: false,
  anchorPlacement: 'top-bottom' as const, // Type assertion for anchorPlacement
};

function App() {
  const location = useLocation();
  const [autoBoot, setAutoBoot] = useState(false);

  // Handle UTM parameters
  useEffect(() => {
    const urlSearchParams = new URLSearchParams(window.location.search);
    const params = Object.fromEntries(urlSearchParams.entries());
    const utmParams = Object.entries(params).reduce<Record<string, string>>((acc, [key, value]) => {
      if (key.startsWith('utm_')) {
        acc[key] = value;
      }
      return acc;
    }, {});

    if (Object.keys(utmParams).length > 0) {
      setCookie('utm_info', JSON.stringify(utmParams));
    }
  }, []);

  // Initialize AOS and GA
  useEffect(() => {
    AOS.init(AOS_CONFIG);

    if (!isStagingEnv()) {
      initGA();
      logPageView();
    }
  }, []);

  // Set Intercom auto boot
  useEffect(() => {
    setAutoBoot(!isStagingEnv());
  }, []);

  // Scroll to top on route change
  useEffect(() => {
    document.documentElement.scrollTo({
      top: 0,
      left: 0,
      behavior: 'auto',
    });
  }, [location.pathname]);

  // Memoize the app content to prevent unnecessary re-renders
  const AppContent = React.memo(() => (
    <AppLayout>
      <Outlet />
    </AppLayout>
  ));

  return (
    <QueryClientProvider client={queryClient}>
      <IntercomProvider appId={INTERCOM_APP_ID} autoBoot={autoBoot}>
        <MetaTags />
        <AuthProvider>
          <ThemeProvider theme={myTheme}>
            <AppContent />
            <MyGlobalStyle />
            <CssUtils theme={undefined} />
          </ThemeProvider>
        </AuthProvider>
      </IntercomProvider>
    </QueryClientProvider>
  );
}

export default React.memo(App);
